import React from "react";

function Aboutme() {
  return (
    <>
      <div className="header-wraper">
        <div className="cardAbout">
          <img
            class="card-img-top"
            src={require("../mypic.jpg")}
            alt="my Pic"
          />
          <p>
            {" "}
            Software Engineer with professional experience in all phases of
            software development life cycle while optimizing quality and
            functionality. Perform analysis on systems, gather requirements, and
            write code to ensure feasibility and maximize functionality. Skilled
            in JavaScript, TypeScript, Node.js, Angular, React.js, React Native,
            MongoDB, HTML, AWS and CSS.
          </p>
        </div>
      </div>
    </>
  );
}

export default Aboutme;
