import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg bg-dark">
      <div className="container">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {/* <FontAwesomeIcon icon={faBars} style={{ color: "#fff" }} /> */}
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
              {/* <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/">
                  Home
                </a>
              </li> */}
              <li className="nav-item">
                <a className="nav-link" href="/about">
                  about me
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/skills">
                  skills
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/exprience">
                  exprience
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/portfolio">
                  portfolio
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/contact">
                  contacts
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/blogs">
                  blogs
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
