import React, { useState } from "react";
import Skills from "./Skills";

function Portfolio() {
  const [portfolio, setPortfolio] = useState([
    {
      name: "Restaurant Managing App",

      discription:
        "The owners must sign up for a new account (using a unique email, phone number, full name, password, and address). Every time they login in successfully. manages lists of foods, daily notes and personal profile",
    },
    { name: "Goal tracker App", discription: "tracking goals progress" },
    {
      name: "Twitter Clone",
      discription: "clones of twitter, almost function as the real twitter",
    },
    {
      name: "car show",
      discription: "signup, login, logout, list cars, remove, edit, add cars",
    },
  ]);
  return (
    <>
      {portfolio.map((item, index) => (
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{item.name}</h5>
            <p className="card-text">{item.discription}</p>
            {/* <a href="#" className="btn btn-primary">
              Go somewhere
            </a> */}
          </div>
        </div>
      ))}
    </>
  );
}

export default Portfolio;
