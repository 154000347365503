import React from "react";

function Exprience() {
  return (
    <div className="exprienceWraper">
      <p className="cardexprince">
        Junior Software Developer (2021-2022) Ensured prompt delivery of
        projects while maintaining budget and meeting customer requirements. •
        Created interactive frontend applications and data visualization
        dashboards. • Built and implemented RESTful APIs. • Trained stakeholders
        on new applications and generated feedback for modifications and bug
        fixes. Technologies Used: JavaScript, TypeScript, Angular, React, React
        Native, Node.js, HTML5, CSS3, MongoDB, AWS.
      </p>
    </div>
  );
}

export default Exprience;
