import React, { useState } from "react";
import axios from "axios";

const Headers = () => {
  const [formState, setFormState] = useState({
    GuestName: "",
    MessageTitle: "",
    Email: "",
    Phone: "",
    Message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
    if (formState.MessageTitle) {
      setLoading(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      body: formState,
      headers: {
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT",
        "X-Requested-With": "*",
      },
    };
    axios
      .post(
        "https://kpgebeggff.execute-api.us-east-2.amazonaws.com/stage",
        formState
      )
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    console.log("form", formState);
    setLoading(false);
    setFormState({
      GuestName: "",
      MessageTitle: "",
      Email: "",
      Phone: "",
      Message: "",
    });
  };

  return (
    <div className="contact-wraper">
      <div className="main-info">
        <a href="#" className="btn-main-offer">
          contact me
        </a>
        <form>
          <div className="mb-1">
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="enter your name"
              value={formState.GuestName}
              onChange={handleChange}
              name="GuestName"
            />
          </div>
          <div className="mb-3">
            <input
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="phone number"
              value={formState.Phone}
              name="Phone"
              onChange={handleChange}
            />
          </div>
          <div className="mb-1">
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="email"
              value={formState.Email}
              name="Email"
              onChange={handleChange}
            />
          </div>
          <div className="mb-1">
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder=" write message title"
              value={formState.MessageTitle}
              name="MessageTitle"
              onChange={handleChange}
            />
          </div>
          <div className="mb-1">
            <input
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="write your message here"
              value={formState.Message}
              name="Message"
              onChange={handleChange}
            />
          </div>

          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={!loading}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Headers;
