import React from "react";

function Blogs() {
  return (
    <>
      <div className="blog-wraper">
        <p className="card">
          <p>
            {" "}
            AWS KMS provides you with centralized control over the encryption
            keys to protect your data. It empowers developers to attach
            encryption functionality easily to their application code directly
            using encrypt and decrypt service APIs or through integrating with
            the AWS Encryption SDK
          </p>
          <p>
            {" "}
            AWS KMS also integrates with AWS CloudTrail to log use of your KMS
            keys for auditing, regulatory, and compliance needs. You can use the
            AWS KMS API to create and manage KMS keys and special features, such
            as custom key stores, and use KMS keys in cryptographic operations.
          </p>
          <p></p>
          <a href="./projectPDf.pdf" target="_blank">
            ...Learn how to creat KMS
          </a>
        </p>
      </div>
    </>
  );
}

export default Blogs;
