import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/Navbar";
import Headers from "./components/Headers";
import Exprience from "./components/Exprience";
import Contact from "./components/Contact";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Skills from "./components/Skills";
import Blogs from "./components/Blogs";
import Aboutme from "./components/Aboutme";
import Portfolio from "./components/Portfolio";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Switch>
          <Route path="/exprience">
            <Exprience />
          </Route>
          <Route path="/skills">
            <Skills />
          </Route>
          <Route path="/portfolio">
            <Portfolio />
          </Route>
          <Route path="/blogs">
            <Blogs />
          </Route>

          <Route path="/contact">
            <Headers />
          </Route>
          <Route path="/">
            <Aboutme />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
